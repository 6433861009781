<template>
  <div>
    <validation-observer
      ref='form'
      v-slot='{invalid}'
    >
      <b-card v-if='!isDataLoaded'>
        <div class='text-center text-primary my-2'>
          <b-spinner class='align-middle' />
        </div>
      </b-card>
      <b-form
        novalidate
        @submit.prevent='onSubmit'
        v-else
      >
        <b-row>
          <b-col
            cols='12'
            xl='9'
            md='8'
          >
            <b-card>
              <b-row>
                <b-col cols='12'>
                  <LocaleTabs
                    :errors='formErrors'
                    name='title'
                  >
                    <template
                      v-for='(i,k,idx) in languages'
                      :slot='`lang${idx}`'
                      slot-scope='slotScope'
                    >
                      <ValidationProvider
                        :key='idx'
                        v-slot='{ errors }'
                        :class="slotScope.selectedTab!==idx?'hidden':''"
                        :vid='`title.${k}`'
                        :name="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        rules='required'
                      >
                        <b-form-group
                          :label="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        >
                          <b-form-input
                            v-model='form.title[k]'
                            :placeholder="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                          />
                          <small class='text-danger'>{{ errors[0] }}</small>
                        </b-form-group>
                      </ValidationProvider>
                    </template>
                  </LocaleTabs>
                </b-col>
                <b-col
                  cols='6'
                  md='6'
                >
                  <validation-provider
                    v-slot='{ errors }'
                    vid='embedded_type'
                    :name="$t('labels.embedded_type')"
                  >
                    <b-form-group
                      :label="$t('labels.embedded_type')"
                    >
                      <v-select
                        v-model='form.embedded_type'
                        :dir='$store.state.appConfig.layout.direction'
                        label='label'
                        :options='news_types_embedded'
                        :reduce='item => item.value'
                        :placeholder="$t('labels.type')"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  cols='12'
                  md='6'
                >
                  <validation-provider
                    v-slot='{ errors }'
                    vid='flag'
                    :name="$t('labels.type')"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('labels.type')"
                    >
                      <v-select
                        v-model='form.flag'
                        :dir='$store.state.appConfig.layout.direction'
                        label='label'
                        :options='news_types'
                        :reduce='item => item.value'
                        @input='updateSelectedData("flag")'
                        :placeholder="$t('labels.type')"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  v-if="form.flag === 'championship'"
                  md='6'
                  cols='12'
                >
                  <validation-provider
                    v-slot='{ errors }'
                    vid='championships'
                    :name="$t('labels.select_championship')"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('labels.select_championship')"
                    >
                      <v-select
                        v-model='form.championships'
                        :dir='$store.state.appConfig.layout.direction'
                        label='name_label'
                        :options='championships'
                        :reduce='item => item.id'
                        @input='updateSelectedData()'
                        :placeholder="$t('labels.select_championship')"
                        multiple
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  v-if="form.flag === 'league'"
                  md='6'
                  cols='12'
                >
                  <validation-provider
                    v-slot='{ errors }'
                    vid='leagues'
                    :name="$t('labels.select_league')"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('labels.select_league')"
                    >
                      <v-select
                        v-model='form.leagues'
                        :dir='$store.state.appConfig.layout.direction'
                        label='title_label'
                        :options='leagues'
                        :reduce='item => item.id'
                        @input='updateSelectedData()'
                        :placeholder="$t('labels.select_league')"
                        multiple
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  v-if='teams.length'
                  cols='12'
                  md='6'
                >
                  <validation-provider
                    v-slot='{ errors }'
                    vid='teams'
                    :name="$t('labels.teams')"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('labels.teams')"
                    >
                      <v-select
                        v-model='form.teams'
                        :dir='$store.state.appConfig.layout.direction'
                        label='name_label'
                        :options='teams'
                        multiple
                        :reduce='item => item.id'
                        :placeholder="$t('labels.teams')"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  v-if="form.content_type === 'video'"
                  cols='12'
                >
                  <ValidationProvider
                    v-slot='{ errors }'
                    name='URL'
                    vid='video_url'
                    :rules="form.content_type === 'video' ? 'required':''"
                  >
                    <b-form-group
                      :label="$t('labels.youtube_url')"
                    >
                      <b-form-input
                        v-model='form.video_url'
                        :placeholder="$t('labels.youtube_url')"
                        autocomplete='off'
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col
                  v-if="form.content_type === 'caricature'"
                  cols='12'
                >
                  <ValidationProvider
                    v-slot='{ errors }'
                    name='URL'
                    vid='video_url'
                    :rules="form.content_type === 'caricature' ? 'required':''"
                  >
                    <b-form-group
                      :label="$t('labels.video_caricature')"
                    >
                      <b-form-input
                        v-model='form.video_url'
                        :placeholder="$t('labels.video_caricature')"
                        autocomplete='off'
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col cols='12'>
                  <LocaleTabs
                    :errors='formErrors'
                    name='description'
                  >
                    <template
                      v-for='(i,k,idx) in languages'
                      :slot='`lang${idx}`'
                      slot-scope='slotScope'
                    >
                      <ValidationProvider
                        :key='idx'
                        v-slot='{ errors }'
                        :class="slotScope.selectedTab!==idx?'hidden':''"
                        :vid='`description.${k}`'
                        :name="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        rules='required'
                      >
                        <b-form-group
                          :label="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        >
                          <quill-editor
                            v-model='form.description[k]'
                            :options='snowOption'
                          />
                          <small class='text-danger'>{{ errors[0] }}</small>
                        </b-form-group>
                      </ValidationProvider>
                    </template>
                  </LocaleTabs>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col
            cols='12'
            xl='3'
            md='4'
          >
            <b-card>
              <b-row>
                <b-col cols='12'>
                  <validation-provider
                    v-slot='{ errors }'
                    vid='content_type'
                    :name="$t('labels.Analytic type')"
                  >
                    <b-form-group :label="$t('labels.Analytic type')">
                      <v-select
                        v-model='form.content_type'
                        :dir='$store.state.appConfig.layout.direction'
                        label='name'
                        :options='types'
                        :reduce='item => item.id'
                        :placeholder="$t('labels.Analytic type')"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols='12'>
                  <validation-provider
                    v-slot='{ errors }'
                    vid='tags'
                    :name="$t('labels.tags')"
                  >
                    <b-form-group :label="$t('labels.tags')">
                      <v-select
                        v-model='form.tags'
                        :dir='$store.state.appConfig.layout.direction'
                        multiple
                        label='name_label'
                        :options='tags'
                        :reduce='item => item.id'
                        :placeholder="$t('labels.tags')"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols='12'>
                  <validation-provider
                    v-slot='{ errors }'
                    vid='analyst'
                    :name="$t('labels.analyst')"
                    rules='required'
                  >
                    <b-form-group :label="$t('labels.analyst')">
                      <v-select
                        v-model='form.analyst_id'
                        :dir='$store.state.appConfig.layout.direction'
                        label='name_label'
                        :options='analyst'
                        :reduce='item => item.id'
                        :placeholder="$t('labels.analyst')"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <hr
                v-if="form.content_type === 'image' || form.content_type === 'caricature' || form.content_type === 'video' || form.content_type === 'text'">
              <b-row
                v-if="form.content_type === 'image' || form.content_type === 'caricature' || form.content_type === 'video' || form.content_type === 'text'">
                <b-col cols='12'>
                  <ValidationProvider
                    v-slot='{ errors }'
                    vid='image'
                    :name="$t('labels.image')"
                    :rules="form.content_type === 'image' || form.content_type === 'caricature' || form.content_type === 'video' ? 'required':''"
                  >
                    <ImagePreview
                      v-model='image'
                      :label="$t('labels.image')"
                    />
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-col>
                <b-col cols='12' />
              </b-row>
              <hr>
              <b-row>
                <b-col cols='12'>
                  <div class='d-flex align-items-center justify-content-between'>
                    <b-card-text class='mb-0'>
                      {{ $t('labels.status') }}
                    </b-card-text>
                    <b-form-checkbox
                      v-model='form.status'
                      checked='true'
                      value='active'
                      unchecked-value='inactive'
                      name='check-button'
                      switch
                    />
                  </div>
                </b-col>
                <b-col cols='12 mt-2'>
                  <div class='d-flex align-items-center justify-content-between'>
                    <b-card-text class='mb-0'>
                      {{ $t('labels.notify_client') }}
                    </b-card-text>
                    <b-form-checkbox
                      v-model='form.notify_client'
                      checked='true'
                      value='1'
                      unchecked-value='0'
                      name='check-button'
                      switch
                    />
                  </div>
                </b-col>

              </b-row>
              <hr>
              <b-row>
                <b-col cols='12 text-center'>
                  <LoadingButton />
                  <b-button
                    type='reset'
                    variant='outline-secondary'
                  >
                    {{ $t('labels.reset') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import _ from 'lodash'
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      isDataLoaded:false,
      tags: [],
      types: [],
      news_types: [],
      championships: [],
      leagues: [],
      teams: [],
      analyst: [],
      image: null,
      news_types_embedded:[],
      snowOption: {
        theme: 'snow',
      },
      form: {
        title: {
          en: null,
          ar: null,
        },
        description: {
          en: null,
          ar: null,
        },
        content_type: 'text',
        analyst_id: null,
        video_url: null,
        flag: null,
        teams: null,
        leagues: null,
        championships: null,
        tags: null,
        image: null,
        status: 'active',
        notify_client: 0,
        embedded_type:null,

      },
    }
  },
  watch: {
    'form.championships': function(val) {
      const ids = this.formatUrlLeagueOrChampionship(val, 'championship')
      if (val.length > 0) {
        this.getTeams(`${ids}`)
      } else {
        this.teams = []
      }
    },

    'form.leagues': function(val) {
      const ids = this.formatUrlLeagueOrChampionship(val)
      if (val.length > 0) {
        this.getTeams(`${ids}`)
      } else {
        this.teams = []
      }
    },

    image(val) {
      if (val) {
        this.form.image = val
      } else {
        this.form.image = 'delete'
      }
    },
  },
  created() {
    this.getTypes()
    this.getTags()
    this.getNewsTypes()
    this.getAnalyst()
    this.getChampionships()
    this.getLeagues()
    this.getNewsEmbeddedTypes()
    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  methods: {
    getNewsEmbeddedTypes() {
      this.axios.get('/lists/news/embeddedTypes')
        .then(res => {
          this.news_types_embedded = res.data
        })
    },
    getTags() {
      this.axios.get('/lists/Tag')
        .then(res => {
          this.tags = res.data
        })
    },
    getTypes() {
      this.axios.get('/analytics/types')
        .then(res => {
          this.types = res.data.data.types
        })
    },
    getAnalyst() {
      this.axios.get('/lists/Analyst')
        .then(res => {
          this.analyst = res.data
        })
    },
    getNewsTypes() {
      this.axios.get('/lists/news/types')
        .then(res => {
          this.news_types = res.data
        })
    },
    getChampionships() {
      this.axios.get('/lists/Championship')
        .then(res => {
          this.championships = res.data
        })
    },
    getLeagues() {
      this.axios.get('/lists/League')
        .then(res => {
          this.leagues = res.data
        })
    },

    getTeams(id) {
      this.axios.get(`/lists/Team?per_page=1${id}`)
        .then(res => {
          this.teams = res.data
        })
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = _.pick(data, [
            'title',
            'description',
            'image',
            'status',
            'flag',
            'leagues',
            'championships',
            'content_type',
            'caricature',
            'notify_client',
            'embedded_type'
          ])
          this.form = {
            ...this.form,
            status: data.status,
            analyst_id: data.analyst_id,
            tags: data.tags.map(item => item.id),
            leagues: data.leagues.map(item => item.id),
            championships: data.championships.map(item => item.id),
            teams: data.teams.map(item => item.id),
            video_url: data.video_url,
            embedded_type: data.embedded_type,

          }
          this.image = data.image
        })
        .catch(() => {
          this.isDataLoaded = true
        })
        .finally(() => {
          this.isDataLoaded = true
        });
    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if (this.isEdit) {
        if (!($form.image instanceof File)) {
          delete $form.image
        }
        // eslint-disable-next-line no-unused-vars
        this.form_data = {
          ...$form,
          _method: 'put',
        }
      }
      return $form
    },

    formatUrlLeagueOrChampionship(val, type = 'league') {
      let param = type == 'league' ? 'league_ids' : 'championship_ids'
      let ids = ''
      for (let key = 0; key < val.length; key++) {
        ids += `&${param}[${key}]=${val[key]}`
      }
      return ids
    },

    updateSelectedData(type = null) {
      this.form.teams = null
      if (type === 'flag') {
        this.teams = []
        this.form.leagues = null
        this.form.championships = null
      }
    },
  },
}
</script>
